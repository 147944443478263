<template>
  <div class="cooperation" v-show="isOut">
    <div class="aft_banner">
      <img :src="$host + banner.litpic" alt="" />
      <!-- <img :src="$host + banner.litpicm" alt="" /> -->
    </div>
    <div class="selectToggle">
      <div class="box flexCenter m160">
        <p class="active">
          <router-link to="/cooperation/atrract">招商合作</router-link>
        </p>
        <p class="">
          <router-link to="/cooperation/business">OEM-ODM业务</router-link>
        </p>
        <p class="">
          <router-link to="/cooperation/information">采购信息公示</router-link>
        </p>
      </div>
    </div>
    <div class="aft_title p80">
      <div class="box">
        <p>{{ zshzInfo.englist_name }}</p>
        <div>{{ zshzInfo.typename }}</div>
      </div>
    </div>
    <div class="cooperationBox">
      <div class="box m160">
        {{ zshzInfo.desc }}
      </div>
    </div>

    <div class="cooperationBox1 m80">
      <div class="aft_title p80">
        <div class="box">
          <p>{{ jxs_cate.englist_name }}</p>
          <div>{{ jxs_cate.typename }}</div>
        </div>
      </div>
      <div class="box m160">
        <div class="content1">六大赋能体系，成就艾芙特伙伴飞速成长</div>
        <div class="content2">
          {{ jxs_cate.pro_desc }}
        </div>
      </div>
      <div class="box1 m160 flexStart">
        <div class="item" v-for="(item, index) in jxs_list" :key="index">
          <div class="number">0{{ index + 1 }}</div>
          <div class="content1">
            <img :src="$host + item.icon" alt="" />
          </div>
          <div class="content2">{{ item.title }}</div>

          <div
            class="content3"
            style="white-space: pre-wrap"
            v-html="item.desc"
          ></div>
        </div>
      </div>
    </div>
    <div class="cooperationBox2">
      <!-- <div class="img"><img src="../assets/images/pro5.png" alt="" /></div> -->
      <div class="aft_title p80">
        <div class="box">
          <p>{{ jmlc_cate.englist_name }}</p>
          <div>{{ jmlc_cate.typename }}</div>
        </div>
      </div>
      <div class="box m160 flexBetween">
        <div
          class="item flexBetween"
          v-for="(item, index) in jmlc_list"
          :key="index"
        >
          <div class="left">0{{ index + 1 }}</div>
          <div class="right">
            <h5>{{ item.title }}</h5>
            <p>{{ item.desc }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="aft_title p80">
      <div class="box">
        <p>{{ jmtj.englist_name }}</p>
        <div>{{ jmtj.typename }}</div>
      </div>
    </div>

    <div class="cooperationBox3">
      <div class="box m160 flexBetween">
        <div class="boxLeft">
          <div class="list">
            <div class="item" v-for="(item, index) in jmtj.desc" :key="index">
              <p>0{{ index + 1 }}</p>
              {{ item }}
            </div>
          </div>
        </div>
        <div class="boxRight">
          <p><img :src="$host + jmtj.img" alt="" /></p>
        </div>
      </div>
      <div class="box1 m160">
        <p style="white-space: pre-wrap" v-html="jmtj.ps"></p>
      </div>
    </div>
    <div class="cooperationBox4">
      <div class="aft_title p80">
        <div class="box">
          <p>{{ cghz_cate.englist_name }}</p>
          <div>{{ cghz_cate.typename }}</div>
        </div>
      </div>
      <div class="box">
        <div class="content1">
          <p v-html="cghz_cate.pro_desc"></p>
        </div>
        <div class="content2 flexStart">
          <div
            class="item flexStart"
            v-for="(item, index) in cghz_list"
            :key="index"
          >
            <div class="left">
              <img :src="$host + item.logo" />
            </div>
            <div class="right">
              <h5>{{ item.title }}</h5>
              <div>
                <p style="white-space: pre-wrap" v-html="item.desc"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="content3">
          <img src="../assets/images/map.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cooperation",
  components: {},
  data() {
    return {
      isOut: false,
      zshzInfo: {},
      banner: {},
      jxs_cate: {},
      jxs_list: [],
      jmlc_cate: {},
      jmlc_list: [],
      jmtj: {},
      cghz_cate: {},
      cghz_list: [],
    };
  },
  created() {
    // 企业简介
    this.$http.get("api.php/Webinfo/getzshz").then(
      function (res) {
        const cooperation = JSON.parse(res.data);

        this.zshzInfo = cooperation.zshz_info;
        this.banner = cooperation.banner;
        this.jxs_cate = cooperation.jxs.cate;
        this.jxs_list = cooperation.jxs.list;
        this.jmlc_cate = cooperation.jmlc.cate;
        this.jmlc_list = cooperation.jmlc.list;
        this.jmtj = cooperation.jmtj;
        this.cghz_cate = cooperation.cghz.cate;
        this.cghz_list = cooperation.cghz.list;
        this.isOut = true;

        // console.log(cooperation);
      },
      function (res) {
        console.log(res.status);
      }
    );
  },
};
</script>

<style lang="less">
@gilroy_b: "gilroy_bold";
@gilroy_r: "gilroy_regular";
@albb_b: "albb_b";
@albb_r: "albb_r";
@albb_l: "albb_l";
@albb_m: "albb_m";
@color: #97c852;

.cooperationBox {
  .box {
    box-sizing: border-box;
    padding: 0 1.4rem;
    font-size: 0.24rem;
    font-family: @albb_b;
    line-height: 0.42rem;
    text-align: justify;
    margin-top: 0.64rem;
  }
}

.cooperationBox1 {
  background: #f5f5f5;
  padding-bottom: 0.9rem;
  .aft_title {
    .box {
      p {
        color: #e5e5e5;
      }
    }
  }
  & > .box {
    box-sizing: border-box;
    padding: 0 1.45rem;
    .content1 {
      font-size: 0.34rem;
      font-family: @albb_b;
      text-align: center;
      color: @color;
      margin-top: 0.54rem;
    }
    .content2 {
      font-size: 0.24rem;
      line-height: 0.42rem;
      color: #666;
      margin-top: 0.4rem;
    }
  }
  .box1 {
    margin-top: 0.8rem;
    .item {
      width: 33.333%;
      box-sizing: border-box;
      border: 1px solid #c3c3c3;
      padding: 0.46rem;
      position: relative;
      .number {
        position: absolute;
        top: 0.46rem;
        right: 0.36rem;
        font-size: 0.2rem;
        color: #999;
        font-family: @gilroy_b;
      }

      .content1 {
        img {
          height: 0.41rem;
          top: 0;
          position: relative;
          transition: 0.4s;
        }
      }
      .content2 {
        font-family: @albb_b;
        font-size: 0.22rem;
        margin-top: 0.36rem;
      }
      .content3 {
        margin-top: 0.25rem;
        font-size: 0.16rem;
        color: #666;
        line-height: 0.24rem;
      }
      &:hover {
        .content1 {
          img {
            top: -0.1rem;
          }
        }
      }
    }
  }
}
.cooperationBox2 {
  background: url(../assets/images/pro5.png) no-repeat 50% 50%;
  background-size: cover;
  padding-bottom: 0.7rem;
  .img {
    img {
      width: 100%;
    }
  }
  .aft_title {
    .box {
      p {
        opacity: 0.25;
      }
      div {
        color: #fff;
      }
    }
  }
  & > .box {
    margin-top: 0.64rem;
    .item {
      box-sizing: border-box;
      padding: 0 0.75rem;
      width: 50%;
      margin-bottom: 0.56rem;
      .left {
        font-family: @gilroy_r;
        font-size: 0.96rem;
        color: #fff;
        opacity: 0.5;
        width: 25%;
      }
      .right {
        width: 75%;
        h5 {
          font-size: 0.26rem;
          font-family: @albb_b;
          color: #fff;
          margin-top: 0.1rem;
        }
        p {
          font-size: 0.2rem;
          color: #fff;
          line-height: 0.34rem;
          margin-top: 8px;
        }
      }
    }
  }
}
.cooperationBox3 {
  margin-top: 0.64rem;
  .box {
    .boxLeft {
      width: 50%;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      .list {
        background: @color;
        padding: 0.64rem;
        .item {
          font-size: 0.26rem;
          color: #fff;
          font-family: @albb_b;
          padding-left: 0.62rem;
          position: relative;
          line-height: 0.45rem;
          margin-bottom: 0.24rem;
          &:last-child {
            margin-bottom: 0;
          }
          p {
            width: 0.34rem;
            height: 0.34rem;
            line-height: 0.34rem;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.4);
            position: absolute;
            top: 6px;
            left: 0;
            font-family: @gilroy_b;
            font-size: 0.14rem;
            text-align: center;
            color: #fff;
          }
        }
      }
    }
    .boxRight {
      width: 50%;
      p {
        width: 120%;
        margin-left: -20%;
        img {
          width: 100%;
        }
      }
    }
  }
  .box1 {
    font-size: 0.22rem;
    color: #666;
    padding: 0.8rem 0;
    p {
      margin-bottom: 5px;
      padding-left: 20.5vw;
    }
    span {
      color: @color;
      font-size: 0.32rem;
      font-family: @gilroy_b;
    }
  }
}
.cooperationBox4 {
  background: #f0f0f0;
  .aft_title {
    .box {
      p {
        color: #e5e5e5;
      }
    }
  }
  & > .box {
    .content1 {
      font-size: 0.24rem;
      font-family: @albb_b;
      line-height: 0.42rem;
      width: 52vw;
      margin: 0.32rem auto 0.48rem;
      span {
        color: @color;
        font-size: 0.34rem;
      }
    }
    .content2 {
      width: 62.5vw;
      margin: auto;
      .item {
        width: 33.333%;
        margin-bottom: 0.2rem;
        .left {
          width: 1.06rem;
          height: 1.06rem;
          border: 2px solid #b0b0b0;
          border-radius: 8px;
          display: flex;
          align-items: center;
          img {
            margin: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
        .right {
          box-sizing: border-box;
          padding: 0 0.3rem;
          width: 72%;
          h5 {
            font-size: 0.18rem;
            color: #000;
          }
          div {
            font-size: 0.15rem;
            color: #898989;
            line-height: 0.2rem;
            margin-top: 10px;
          }
        }
      }
    }
    .content3 {
      text-align: center;
      padding: 0.64rem 0 0.9rem;
      img {
        width: 90vw;
      }
    }
  }
}
@media screen and(max-width:1500px) {
  .cooperationBox3 .box .boxLeft .list .item {
    font-size: 0.2rem;
  }
  .cooperationBox4 > .box .content2 .item .left {
    width: 0.9rem;
    height: 0.9rem;
  }
  .cooperationBox4 > .box .content2 .item .right {
    width: 70%;
  }
  .recruitmentBox > .box .list .boxTop .boxLeft h5 {
    font-size: 0.3rem;
  }
  .recruitmentBox > .box .list .boxTop .boxRight .bot p {
    font-size: 0.15rem;
    width: 1.2rem;
  }
  .recruitmentBox > .box .list .boxBot .content2 .left img {
    width: 0.9rem;
  }
}

@media screen and(max-width:768px) {
  .cooperationBox .box {
    padding: 0;
  }
  .cooperationBox1 > .box {
    padding: 0;
  }
  .cooperationBox1 > .box .content1 {
    font-size: 0.28rem;
  }
  .cooperationBox1 .box1 .item {
    width: 50%;
  }
  .cooperationBox2 > .box .item {
    width: 100%;
    padding: 0;
  }
  .cooperationBox2 > .box .item .left {
    font-size: 0.56rem;
    width: 20%;
  }
  .cooperationBox2 > .box .item .right {
    width: 80%;
  }
  .cooperationBox3 .box1 p {
    padding-left: 0;
    line-height: 0.5rem;
  }
  .cooperationBox4 > .box .content1 {
    width: 90vw;
  }
  .cooperationBox4 > .box .content2 {
    width: 90vw;
  }
  .cooperationBox4 > .box .content2 .item {
    width: 50%;
    margin-bottom: 0.5rem;
  }
  .cooperationBox4 > .box .content2 .item .right div {
    line-height: 0.3rem;
  }
  .cooperationBox3 .box .boxLeft {
    width: 100%;
  }
  .cooperationBox3 .box .boxRight {
    width: 100%;
  }
  .cooperationBox3 .box .boxRight p {
    width: 100%;
    margin-left: 0;
  }
  .cooperationBox3 .box1 {
    padding: 0.5rem 0;
  }
  .cooperationBox3 .box .boxLeft .list {
    padding: 0.4rem;
  }
  .cooperationBox3 .box .boxLeft .list .item p {
    width: 0.5rem;
    height: 0.5rem;
    line-height: 0.5rem;
    top: -2px;
  }
}

@media screen and(max-width:699px) {
  .cooperationBox4 > .box .content2 .item {
    width: 100%;
  }
}
</style>
